var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-h5"},[_c('div',{staticClass:"my-h5-top"},[_c('span',{staticClass:"ibtn",on:{"click":_vm.onReset}},[_vm._v("重 置")]),_c('div',{staticClass:"my-h5-btns"},[_c('span',{staticClass:"ibtn",attrs:{"id":"save"},on:{"click":_vm.onSave}},[_vm._v("保 存")])])]),_c('div',{ref:"editorBody",staticClass:"my-h5-body",attrs:{"id":"my-h5-body-contetn"}},[_c('div',{staticClass:"editor-body-content"},[_c('div',{ref:"imageTofile",staticClass:"editor-body-notepad",staticStyle:{"position":"relative"},style:({
          width: Math.ceil((_vm.$route.params.type === '1' ? '1920' : '1080') *_vm.zoom) + 'px',
          height: Math.ceil((_vm.$route.params.type === '1' ? '1080' : '1920') * _vm.zoom) + 'px'
        })},[_c('div',{staticStyle:{"transform-origin":"0 0"},style:({
              transform: ("scale(" + _vm.zoom + ")"),
              width: (_vm.$route.params.type === '1' ? '1920' : '1080') + 'px',
              height: (_vm.$route.params.type === '1' ? '1080' : '1920') + 'px',
            })},_vm._l((_vm.controlList),function(item,index){return _c('vdr',{key:index,attrs:{"x":item.infoX,"y":item.infoY,"w":item.infoWidth,"h":item.infoHeight,"index":index,"scaleRatio":_vm.zoom,"parent":true},on:{"resizestop":_vm.onResize,"dragstop":_vm.onDrag,"activated":function($event){return _vm.onActivated(index)}}},[(item.type === 'img')?_c('img',{staticClass:"control-img animate__animated",class:item.animation.animationClassName,style:({
                       animationIterationCount:item.animation.animationNum,
                       animationDuration:item.animation.animationTime + 's',
                       animationDelay:item.animation.animationDelay,
                       textDecoration:item.decoration,
                   }),attrs:{"id":item.id,"src":item.url}}):_vm._e(),(item.type === 'font')?_c('div',{staticClass:"animate__animated",class:item.animation.animationClassName,style:({
                       animationIterationCount:item.animation.animationNum,
                       animationDuration:item.animation.animationTime + 's',
                       animationDelay:item.animation.animationDelay,
                       textDecoration:item.decoration,
                       height: '100%','fontStyle':item.incline,
                       fontWeight:item.bold, 'fontSize':(item.fontSize) + 'px',
                       color: item.fontColor,
                       textAlign:item.alignment,
                       whiteSpace: 'pre',
                       letterSpacing: item.letterSpacing + 'px',
                       lineHeight: item.lineSpacing
                   }),attrs:{"id":item.id}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])}),1)])])]),_c('div',{staticClass:"my-h5-bottom"},[(_vm.controlType === 'img')?_c('H5img'):_vm._e(),(_vm.controlType === 'font')?_c('H5font'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }